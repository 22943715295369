import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
    borderRadius: 0,
    marginBottom: theme.spacing(0.5),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    ...(selected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
}));

const LeftNavbar = ({ options }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ 
            height: '100%', 
            bgcolor: 'background.paper', 
            borderRight: '1px solid',
            borderColor: 'divider',
        }}>
            <Typography variant="h6" sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
                {options.title || 'Navigation'}
            </Typography>
            <List component="nav" sx={{ width: '100%' }}>
                {options.items?.map((option) => (
                    <StyledListItem
                        key={option.id}
                        selected={option.selected}
                        onClick={() => navigate(option.path)}
                    >
                        {option.icon && <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>{option.icon}</ListItemIcon>}
                        <ListItemText primary={option.label} />
                    </StyledListItem>
                ))}
            </List>
        </Box>
    );
};

export default LeftNavbar;