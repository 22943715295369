import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

const ProfilePage = () => {
  const [userData, setUserData] = useState({ username: '', email: '' });
  const [initialUserData, setInitialUserData] = useState({ username: '', email: '' });
  const [apiKeys, setApiKeys] = useState([]);
  const [error, setError] = useState('');
  const { token, user, login, refreshToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, keysResponse] = await Promise.all([
          axios.get(`${API_URL}/auth/users/me`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${API_URL}/auth/api-keys/`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        const fetchedUserData = {
          username: userResponse.data.username,
          email: userResponse.data.email
        };
        setUserData(fetchedUserData);
        setInitialUserData(fetchedUserData);
        setApiKeys(keysResponse.data);
      } catch (error) {
        setError('Failed to fetch user details or API keys');
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [token, API_URL]);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
    setError('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(`${API_URL}/auth/users/me`, userData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      login(token, response.data, refreshToken);
      navigate('/');
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error);
      const errorMessage = error.response?.data?.username ||
                           error.response?.data?.email ||
                           'Failed to update profile';
      setError(errorMessage);
    }
  };

  const createApiKey = async () => {
    try {
      const response = await axios.post(`${API_URL}/auth/api-keys/create/`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setApiKeys([...apiKeys, response.data]);
    } catch (error) {
      setError('Failed to create API key');
      console.error('Error creating API key:', error);
    }
  };

  const deactivateApiKey = async (keyId) => {
    try {
      await axios.post(`${API_URL}/auth/api-keys/deactivate/${keyId}/`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setApiKeys(apiKeys.filter(key => key.id !== keyId));
    } catch (error) {
      setError('Failed to deactivate API key');
      console.error('Error deactivating API key:', error);
    }
  };

  const hasChanges = userData.email !== initialUserData.email || userData.username !== initialUserData.username;

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h4" gutterBottom>Profile</Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Typography component="h2" variant="h5" gutterBottom>Edit Profile</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={userData.email}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={userData.username}
              onChange={handleChange}
            />
            {hasChanges && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Save Changes
              </Button>
            )}
            {error && <Typography color="error">{error}</Typography>}
          </form>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography component="h2" variant="h5" gutterBottom>API Keys</Typography>
          <List>
            {apiKeys.map((key) => (
              <ListItem key={key.id} divider>
                <ListItemText
                  primary={key.key.substring(0, 10) + '...'}
                  secondary={`Created: ${new Date(key.created_at).toLocaleString()}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => deactivateApiKey(key.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={createApiKey}
            sx={{ mt: 2 }}
          >
            Create New API Key
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfilePage;