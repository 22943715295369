import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import DatasetIcon from '@mui/icons-material/Storage';
import Datasets from './Datasets';
import { algoTradingNavbarOptions } from '../../config/navbarOptions';

const AlgoTradingBotsPage = ({ setNavbarOptions }) => {
    useEffect(() => {
        setNavbarOptions(algoTradingNavbarOptions);
    }, [setNavbarOptions]);

    const [selectedOption, setSelectedOption] = useState('datasets');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setNavbarOptions({
            title: 'Algo Trading',
            items: [
                { 
                    id: 'datasets', 
                    label: 'Datasets', 
                    icon: <DatasetIcon />, 
                    selected: selectedOption === 'datasets',
                    onClick: () => setSelectedOption('datasets')
                },
                // Add more options here
            ]
        });
    }, [setNavbarOptions, selectedOption]);

    const renderContent = () => {
        switch(selectedOption) {
            case 'datasets':
                return <Datasets />;
            // Add more cases for other options in the future
            default:
                return <Datasets />;
        }
    };

    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} sx={{ height: '100%', overflow: 'auto' }}>
                {renderContent()}
            </Grid>
        </Grid>
    );
};

export default AlgoTradingBotsPage;