import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import StorageIcon from '@mui/icons-material/Storage';

export const homeNavbarOptions = {
    title: 'Home',
    items: [
        { 
            id: 'home', 
            label: 'Dashboard', 
            icon: <HomeIcon />, 
            path: '/'
        },
        { 
            id: 'sentiment', 
            label: 'Sentiment Analysis', 
            icon: <BarChartIcon />, 
            path: '/sentiment'
        },
    ]
};

export const algoTradingNavbarOptions = {
    title: 'Algo Trading',
    items: [
        { 
            id: 'datasets', 
            label: 'Datasets', 
            icon: <StorageIcon />, 
            path: '/algo-trading-bots/datasets'
        },
    ]
};

// You can add more navbar options for different sections here